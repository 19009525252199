import { useEffect, useRef, useState } from 'react';

import { StorePlatform } from '../../../enums/store-platform.enum';

import DownloadApp from '../../../components/downloadApp/DownloadApp';
import ScrollInAnimationItem from '../../../components/scrollInAnimationItem/ScrollInAnimationItem';

import { useHorizontalScroll } from '../../../hooks/useHorizontalScroll';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { useWindowSize } from '../../../hooks/useWindowSize';

import { ReactComponent as SlideScroll } from '../../../assets/landing/icons/slide-scroll.svg';
import PlayersSectionImage from '../../../assets/landing/players/players-section-game.png';
import PlayersSectionNftCard from '../../../assets/landing/players/players-section-nft-card.png';
import PlayersSectionPercentageLeft from '../../../assets/landing/players/players-section-percentage-left.png';
import PlayersSectionPercentageRight from '../../../assets/landing/players/players-section-percentage-right.png';
import PlayersSectionTradingRoyalties from '../../../assets/landing/players/players-section-trading-royalties.png';

import styles from './LandingPlayersSection.module.scss';

const LandingPlayersSection = ({ mainAnimationLoaded }: { mainAnimationLoaded: boolean }) => {
  const scrollRef = useHorizontalScroll();
  const screenSize = useScreenSize();
  const [windowWidth] = useWindowSize();
  const [showScrollSection, setShowScrollSection] = useState<boolean>(false);
  const nftCardElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }

    setShowScrollSection(scrollRef.current.clientWidth < scrollRef.current.scrollWidth);
  }, [scrollRef, screenSize]);

  useEffect(() => {
    if (!showScrollSection || !scrollRef.current || !nftCardElementRef.current) {
      return;
    }

    const nftCardWidth = nftCardElementRef.current.getBoundingClientRect().width;
    const offsetLeft = nftCardElementRef.current.offsetLeft - (windowWidth - nftCardWidth) / 2;

    scrollRef.current.scrollTo(offsetLeft, 0);
  }, [scrollRef, nftCardElementRef, showScrollSection, windowWidth]);

  return (
    <div className={styles['section']}>
      <ScrollInAnimationItem delay="0.1s">
        <h2 className={styles['title']}>
          royalties from <b>millions</b> of players
        </h2>
      </ScrollInAnimationItem>
      <ScrollInAnimationItem delay="0.2s">
        <p className={`landing-main-text ${styles['main-text']}`}>
          Experience two connected worlds! Owners of digital assets earn ongoing royalties from millions of mobile
          gamers, while traditional players enjoy exclusive premium content that enhances their gameplay and immersion.
        </p>
        <p className={`landing-main-text ${styles['main-text']}`}>Up to 961% APY!</p>
      </ScrollInAnimationItem>
      <div className={styles['content-wrapper']}>
        <div ref={scrollRef} className={styles['content']}>
          <ScrollInAnimationItem delay="0.3s" threshold={0.1}>
            <img
              alt=""
              className={styles['game-stores']}
              src={mainAnimationLoaded ? PlayersSectionImage : ''}
              width="456"
              height="446"
            />
            <div className={styles['stores']}>
              <DownloadApp className={styles['stores__download']} platform={StorePlatform.googlePlatform}></DownloadApp>
              <DownloadApp className={styles['stores__download']} platform={StorePlatform.appleStore}></DownloadApp>
            </div>
          </ScrollInAnimationItem>
          <ScrollInAnimationItem delay="0.4s" threshold={0.1}>
            <img
              alt=""
              className={styles['percentage-left']}
              src={mainAnimationLoaded ? PlayersSectionPercentageLeft : ''}
              width="248"
              height="504"
            />
          </ScrollInAnimationItem>
          <div ref={nftCardElementRef}>
            <ScrollInAnimationItem delay="0.5s" threshold={0.1}>
              <img
                alt=""
                className={styles['nft-card']}
                src={mainAnimationLoaded ? PlayersSectionNftCard : ''}
                width="357"
                height="480"
              />
            </ScrollInAnimationItem>
          </div>
          <ScrollInAnimationItem delay="0.6s" threshold={0.1}>
            <img
              alt=""
              className={styles['percentage-right']}
              src={mainAnimationLoaded ? PlayersSectionPercentageRight : ''}
              width="192"
              height="504"
            />
          </ScrollInAnimationItem>
          <ScrollInAnimationItem delay="0.7s" threshold={0.1}>
            <img
              alt=""
              className={styles['trading-royalties']}
              src={mainAnimationLoaded ? PlayersSectionTradingRoyalties : ''}
              width="357"
              height="454"
            />
          </ScrollInAnimationItem>
        </div>
      </div>
      {showScrollSection && <SlideScroll className={styles['slide-scroll-icon']} />}
    </div>
  );
};

export default LandingPlayersSection;
