import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ILootboxOwned } from 'models/lootbox/i-lootbox-owned.interface';

import { LootboxTabs } from 'enums/lootbox/lootbox-tabs.enum';

import { ERROR_MESSAGES } from 'constants/error-messages.constant';
import { LOOTBOX_FAQ_LIST } from 'constants/faq-list.constant';

import { addErrorMessageAction, setShowSelectWalletModal } from 'actions/global-actions';

import BadgeRound from 'components/badgeRound/BadgeRound';
import Card from 'components/card/Card';
import FaqList from 'components/faqList/FaqList';
import FaqListItem from 'components/faqList/FaqListItem/FaqListItem';
import PageHeader from 'components/pageHeader/PageHeader';
import Tab from 'components/tab/Tab';
import Tabs from 'components/tabs/Tabs';

import { useLootboxesListings } from '../hooks/useLootboxesListings';
import { useLootboxesOwned } from '../hooks/useLootboxesOwned';
import { useLootboxEvents } from 'hooks/events/useLootboxEvents';
import { useIsWeb3Allowed } from 'hooks/useIsWeb3Allowed';

import LootboxMarket from '../lootboxMarket/LootboxMarket';
import LootboxOwned from '../lootboxOwned/LootboxOwned';
import styles from './LootboxMainView.module.scss';

const LootboxMainView = () => {
  const { t } = useTranslation('notifications');
  const web3Allowed = useIsWeb3Allowed();
  const dispatch = useDispatch();
  const [showBuyLootboxes, setShowBuyLootboxes] = useState<boolean>();
  const [showOwnedLootboxes, setShowOwnedLootboxes] = useState<boolean>();
  const [activeTab, setActiveTab] = useState<LootboxTabs>();
  const {
    loading: ownedLootboxesLoading,
    loadingTime: ownedLootboxesLoadingTime,
    lootboxes: ownedLootboxes,
    lootboxesCount: ownedLootboxesCount,
    fetchLootboxesDetails: fetchOwnedLootboxesDetails,
  } = useLootboxesOwned();
  const {
    loading: marketLootboxesLoading,
    loadingTime: marketLootboxesLoadingTime,
    lootboxesListings: marketLootboxesListings,
    fetchRewardDetails: fetchMarketRewardDetails,
  } = useLootboxesListings();
  const faqList = LOOTBOX_FAQ_LIST;

  const { sendLootboxGetMarketplaceEvent, sendLootboxGetOwnedEvent } = useLootboxEvents();

  useEffect(() => {
    if (!marketLootboxesLoadingTime) return;

    sendLootboxGetMarketplaceEvent({ time: marketLootboxesLoadingTime });
  }, [marketLootboxesLoadingTime]);

  useEffect(() => {
    if (!ownedLootboxes || !ownedLootboxesLoadingTime) return;

    ownedLootboxes?.map((lootbox: ILootboxOwned) =>
      sendLootboxGetOwnedEvent({
        lootboxId: lootbox.id,
        lootboxAmount: parseInt(lootbox.quantityOwned),
        time: ownedLootboxesLoadingTime,
      })
    );
  }, [ownedLootboxes, ownedLootboxesLoadingTime]);

  useEffect(() => {
    activateBuyLootboxes();
  }, []);

  const activateBuyLootboxes = () => {
    setActiveTab(LootboxTabs.buy);
    setShowBuyLootboxes(true);
    setShowOwnedLootboxes(false);
  };

  const beforeActivateOwnedLootboxes = () => {
    if (web3Allowed) {
      return true;
    }

    dispatch(addErrorMessageAction(t(ERROR_MESSAGES.unauthorized)));
    dispatch(setShowSelectWalletModal(true));

    return false;
  };

  const activateOwnedLootboxes = () => {
    setActiveTab(LootboxTabs.owned);
    setShowBuyLootboxes(false);
    setShowOwnedLootboxes(true);
  };

  const marketLootboxesClassNames = [
    styles['lootbox-main-view__section'],
    showBuyLootboxes ? styles['lootbox-main-view__section--visible'] : styles['lootbox-main-view__section--hidden'],
    marketLootboxesLoading ? '' : styles['lootbox-main-view__section--loaded'],
  ];
  const ownedLootboxesClassNames = [
    styles['lootbox-main-view__section'],
    showOwnedLootboxes ? styles['lootbox-main-view__section--visible'] : styles['lootbox-main-view__section--hidden'],
    ownedLootboxesLoading ? '' : styles['lootbox-main-view__section--loaded'],
  ];

  return (
    <>
      <PageHeader className={styles['lootbox-main-view__title']}>great digital assets hidden in loot boxes</PageHeader>
      <p className={styles['lootbox-main-view__subtitle']}>
        Each loot box allows you to collect unique fractional digital assets representing real-world locations, enabling
        you to complete your collection and earn royalties in RMV.
      </p>
      <Card theme="medium" className={styles['lootbox-main-view__card']}>
        <div className={styles['lootbox-main-view__tabs-wrapper']}>
          <Tabs className={styles['lootbox-main-view__tabs']}>
            <Tab active={activeTab === LootboxTabs.buy} className="responsive-tab" onActivate={activateBuyLootboxes}>
              buy loot boxes
            </Tab>
            <Tab
              active={activeTab === LootboxTabs.owned}
              className="responsive-tab"
              onActivate={activateOwnedLootboxes}
              beforeActivate={beforeActivateOwnedLootboxes}
            >
              <div className={styles['lootbox-main-view__owned-tab']}>
                owned loot boxes {ownedLootboxesCount ? <BadgeRound value={ownedLootboxesCount} /> : <></>}
              </div>
            </Tab>
          </Tabs>
        </div>
        <div className={styles['lootbox-main-view__lootboxes']}>
          <LootboxOwned
            className={ownedLootboxesClassNames.join(' ')}
            loading={ownedLootboxesLoading}
            lootboxes={ownedLootboxes}
            fetchLootboxesDetails={fetchOwnedLootboxesDetails}
            openBuyLootboxes={activateBuyLootboxes}
          />
          <LootboxMarket
            className={marketLootboxesClassNames.join(' ')}
            loading={marketLootboxesLoading}
            lootboxesListings={marketLootboxesListings}
            fetchRewardDetails={fetchMarketRewardDetails}
          />
        </div>
        <h2 className={styles['lootbox-main-view__faq-title']}>Loot boxes FAQ</h2>
        <FaqList className={styles['lootbox-main-view__faq']}>
          {faqList.map(({ title, details }) => (
            <FaqListItem key={title} title={title} className={styles['lootbox-main-view__faq-wrapper']}>
              {details}
            </FaqListItem>
          ))}
        </FaqList>
      </Card>
    </>
  );
};

export default LootboxMainView;
