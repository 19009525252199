import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes.constant';

import { ScrollContext } from '../../../contexts/ScrollContext';

import Button from '../../../components/buttons/Button';
import ScrollInAnimationItem from '../../../components/scrollInAnimationItem/ScrollInAnimationItem';

import { useLandingScrollSectionState } from '../../../hooks/landing/useLandingScrollSectionState';

import OurWhitepaperBackground from '../../../assets/landing/our-whitepaper/our-whitepaper-background.jpg';
import WhitepaperCards from '../../../assets/landing/our-whitepaper/whitepaper-cards.png';

import LandingInfoCard from '../landingInfoCard/LandingInfoCard';
import styles from './LandingOurWhitepaperSection.module.scss';

const LandingOurWhitepaperSection = () => {
  const navigate = useNavigate();
  const classNames = ['landing-page-container', styles['section']].join(' ');
  const { sectionRefs } = useContext(ScrollContext);
  useLandingScrollSectionState('whitepaper');

  return (
    <section ref={sectionRefs.whitepaper}>
      <div className={classNames}>
        <ScrollInAnimationItem className={styles['card-wrapper']} delay="0.5s" threshold={0.1}>
          <LandingInfoCard
            className={styles['card']}
            contentClassName={styles['card__content']}
            title={
              <div>
                meet our <b>whitepaper</b>
              </div>
            }
            content={
              <div>
                Discover how we are revolutionizing the connection between the real world and the Metaverse. Our
                whitepaper outlines how you can own and monetize digital assets, unlocking new opportunities in the
                evolving digital economy.
              </div>
            }
            footer={
              <Button size="medium" color="primary" onClick={() => navigate(ROUTES.WHITEPAPER)}>
                read whitepaper
              </Button>
            }
            graphics={
              <>
                <img className={styles['card__background']} src={OurWhitepaperBackground} alt="" />
                <img className={styles['card__cards-image']} src={WhitepaperCards} alt="" />
              </>
            }
          />
        </ScrollInAnimationItem>
      </div>
    </section>
  );
};

export default LandingOurWhitepaperSection;
