export const LANDING_ROADMAP = [
  {
    title: 'RMV token',
    items: [
      {
        title: 'Major exchanges',
        checked: true,
      },
      {
        title: 'Governance mechanism',
        checked: true,
      },
      {
        title: 'Hard currency in games',
        checked: false,
      },
    ],
  },
  {
    title: 'Adding new games',
    items: [
      {
        title: 'Game launchpad',
        checked: true,
      },
      {
        title: 'Games accelerator',
        checked: true,
      },
      {
        title: 'Games partnership',
        checked: false,
      },
    ],
  },
  {
    title: 'Reality metaverse',
    items: [
      {
        title: 'Expansion to other blockchains',
        checked: false,
      },
      {
        title: '$RRUSH Booster Token',
        checked: true,
      },
      {
        title: '$TRUMPET meme/utility token',
        checked: false,
      },
      {
        title: 'Reality metaverse - the world',
        checked: false,
      },
      {
        title: 'RWA',
        checked: false,
      },
      {
        title: 'AI Agent',
        checked: false,
      },
      {
        title: 'Gaminification of Digital Assets',
        checked: false,
      },
    ],
  },
  {
    title: 'DIGITAL ASSETS',
    items: [
      {
        title: 'Exclusive club for assets owners',
        checked: true,
      },
      {
        title: 'AR assets',
        checked: false,
      },
      {
        title: 'AAA game',
        checked: false,
      },
      {
        title: 'Assets licencing',
        checked: false,
      },
      {
        title: 'Assets features and upgrades',
        checked: false,
      },
    ],
  },
  {
    title: 'Games production',
    items: [
      {
        title: 'Landlord GO',
        checked: true,
      },
      {
        title: 'Reality Rush',
        checked: true,
      },
      {
        title: 'Weather Challenge',
        checked: false,
      },
      {
        title: 'AAA Game (TBA)',
        checked: false,
      },
      {
        title: 'Speculate Game',
        checked: false,
      },
      {
        title: 'Foodtruck Tycoon',
        checked: false,
      },
      {
        title: 'Football Manager',
        checked: false,
      },
      {
        title: 'Donut Trumpet Tycoon',
        checked: false,
      },
      {
        title: 'StreetVers',
        checked: false,
      },
    ],
  },
  {
    title: 'Railway 3.0',
    items: [
      {
        title: 'Web3 Coffee space ',
        checked: false,
      },
      {
        title: 'Web3 Event space',
        checked: true,
      },
      {
        title: 'Web3 Coworking ',
        checked: false,
      },
      {
        title: 'Web3 Accelerator',
        checked: false,
      },
    ],
  },
];
