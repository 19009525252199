import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ROUTES } from 'constants/routes.constant';

import { ScrollContext } from 'contexts/ScrollContext';

import Button from 'components/buttons/Button';
import FreeRMVControl from 'components/freeRMVControl/FreeRMVControl';
import MenuElement from 'components/menu/menuElement/MenuElement';
import MenuWalletButton from 'components/menu/menuWalletButton/MenuWalletButton';
import SocialsBar from 'components/socials/SocialsBar';

import { useHomePageNavigate } from 'hooks/useHomePageNavigate';

import RMV from 'assets/icons/RMV.png';
import RealityGamesLogo from 'assets/logos/reality-metaverse-logo.png';

import styles from './LandingHeader.module.scss';

interface ILandingHeader {
  className?: string;
  isStaking: boolean;
}

const LandingHeader = ({ className, isStaking }: ILandingHeader) => {
  const { activeScrollSection, executeScroll } = useContext(ScrollContext);
  const { navigateToHomePage } = useHomePageNavigate();
  const location = useLocation();

  return (
    <div className={`${className || ''} ${styles['menu']}`}>
      <div className={styles['menu__logo']}>
        <Link to="/" aria-label="Landing page">
          <span className="avatar">
            <img src={RealityGamesLogo} alt="Reality Metaverse" />
          </span>
        </Link>
      </div>
      <nav className={styles['nav']}>
        <ul className={styles['nav-list']}>
          <li>
            <MenuElement
              title="RMV"
              link="/"
              icon={<img src={RMV} alt="RMV" />}
              textClassName={styles['nav-text']}
              activeTextClassName={styles['nav-text--active']}
              onClick={() => executeScroll('rmv')}
              active={activeScrollSection === 'rmv'}
            />
          </li>
          <li>
            <MenuElement
              title="Digital Assets"
              link="/"
              textClassName={styles['nav-text']}
              activeTextClassName={styles['nav-text--active']}
              onClick={() => executeScroll('realityNFT')}
              active={activeScrollSection === 'realityNFT'}
            />
          </li>
          <li>
            <MenuElement
              title="Games"
              active={location.pathname === ROUTES.GAMES}
              link={ROUTES.GAMES}
              textClassName={[styles['nav-text'], styles['nav-text__staking']].join(' ')}
              activeTextClassName={styles['nav-text--active']}
            />
          </li>
          <li>
            <MenuElement
              title="Staking"
              active={location.pathname === ROUTES.PERIODICAL_STAKING}
              link={ROUTES.PERIODICAL_STAKING}
              textClassName={[styles['nav-text'], styles['nav-text__staking']].join(' ')}
              activeTextClassName={styles['nav-text--active']}
            />
          </li>
          <li>
            <MenuElement
              title="Whitepaper"
              link="/"
              active={activeScrollSection === 'whitepaper'}
              textClassName={styles['nav-text']}
              activeTextClassName={styles['nav-text--active']}
              onClick={() => executeScroll('whitepaper')}
            />
          </li>
          <li className={styles['nav-list__item']}>
            <MenuElement
              title="News"
              active={location.pathname === ROUTES.PROMOTIONS}
              link={ROUTES.PROMOTIONS}
              textClassName={styles['nav-text']}
              activeTextClassName={styles['nav-text--active']}
            />
          </li>
        </ul>
      </nav>
      <SocialsBar
        order={[
          { type: 'medium' },
          { type: 'discord' },
          { type: 'twitter' },
          { type: 'telegramChat' },
          { type: 'telegram' },
          { type: 'polygonscan' },
          { type: 'coinmarketcap' },
        ]}
        className={styles['nav-bar_social__list']}
        iconClassName={styles['nav-bar__social___icon']}
      />
      <div className="tw-flex tw-items-center tw-justify-center tw-gap-4">
        <FreeRMVControl />
        <Button
          className={`${styles['banner-button']} ${styles['banner-button__text']}`}
          size="medium"
          onClick={navigateToHomePage}
        >
          <span className={styles['text']}>Launch app</span>
        </Button>
      </div>
      {isStaking && (
        <div className="tw-ml-5">
          <MenuWalletButton />
        </div>
      )}
    </div>
  );
};

export default LandingHeader;
