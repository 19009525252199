export enum AssetField {
  Id = 'id',
  Address = 'address',
  Name = 'name',
  TokenId = 'token_id',
  ImageOriginalUrl = 'image_original_url',
  AnimationUrl = 'animation_url',
  MarkerUrl = 'marker_url',
  Traits = 'traits',
}

export enum AssetDetailsField {
  TotalShares = 'total_shares',
  Contract = 'contract',
  SharesCurrentlyOnSale = 'shares_currently_on_sale',
  Description = 'description',
  AssetOriginalUrl = 'asset_original_url',
  ActiveTrades = 'active_trades',
  OwnedShares = 'owned_shares',
  ActiveShares = 'active_shares',
  OwnersCount = 'owners_count',
  NftValue = 'nft_value',
}

export enum AssetTradeField {
  TotalShares = 'total_shares',
  LowestPossiblePrice = 'lowest_possible_price',
  Contract = 'contract',
  SharesCurrentlyOnSale = 'shares_currently_on_sale',
  LastSalePrice = 'last_sale_price',
  LastWeekIncome = 'last_week_income',
}

export enum LandingField {
  TotalShares = 'total_shares',
  LowestPossiblePrice = 'lowest_possible_price',
}

export enum ProfileField {
  Contract = 'contract',
  LastSalePrice = 'last_sale_price',
  LastWeekIncome = 'last_week_income',
  LastDayIncome = 'last_day_income',
  ActiveTrades = 'active_trades',
  OwnedShares = 'owned_shares',
}

export enum ProfileTradeField {
  Id = 'id',
  Amount = 'amount',
  AssetId = 'asset_id',
  CurrencyId = 'currency_id',
  ExpirationTime = 'expiration_time',
  OwnedAwaitingOffer = 'owned_awaiting_offer',
  Price = 'price',
  Stats = 'stats',
  Status = 'status',
}

export enum ProfileTradeOfferField {
  Id = 'id',
  CurrencyAmount = 'currency_amount',
  Sale = 'sale',
  TradeValue = 'trade_value',
}

export enum MarketTradeField {
  AuctionHouseExpirationDatetime = 'auction_house_expiration_datetime',
  AuctionId = 'auction_id',
  AuctionPriceIncrement = 'auction_price_increment',
  ContractType = 'contract_type',
  StableExchangeRate = 'stable_exchange_rate',
  Type = 'type',
  CurrentHighestBidPrice = 'current_highest_bid_price',
  Nonce = 'nonce',
  Seller = 'seller',
  Signature = 'signature',
  StateHash = 'state_hash',
}
