export enum ProfileAssetCategoryEnum {
  airport = 'airport',
  city = 'city',
  country = 'country',
  famousBuilding = 'famous building',
  famousStreet = 'famous street',
  historicSite = 'historic site',
  hq = 'hq',
  parliament = 'parliament',
  publicPark = 'public park',
  touristAttraction = 'tourist attraction',
  trainStation = 'train station',
}

export enum ProfileAssetOrderingEnum {
  newestFirst = '-date_created',
  oldestFirst = 'date_created',
  highestPrice = '-price',
  lowestPrice = 'price',
  closestDue = 'expiration_time',
  furthestDue = '-expiration_time',
}

export enum ProfileAssetListingStatusEnum {
  ACTIVE,
  FINISHED,
  CANCELLED,
  EXPIRED,
}

export enum ProfileAssetListingStatusLabelEnum {
  ACTIVE = 'Active',
  FINISHED = 'Finished',
  CANCELLED = 'Cancelled',
  EXPIRED = 'Expired',
}

export interface IProfileAssetsFilter {
  contract?: string;
  token_id?: string;
  contract__network?: string;
  page?: string;
  page_size?: string;
  ordering?: ProfileAssetOrderingEnum;
  search?: string;
  exclude_status?: ProfileAssetListingStatusEnum;
  show_status?: ProfileAssetListingStatusEnum;
  offered_by_me?: string;
  country?: string;
  category?: string;
  created_by_me?: string;
  disable_aggregation?: string;
}
