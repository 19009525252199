import { IFaqItem } from '../types/i-faq-item.interface';

export const LOOTBOX_FAQ_LIST: IFaqItem[] = [
  {
    title: 'What are Reality digital assets loot boxes?',
    details:
      'Loot boxes are special packages that contain random Reality digital assets. These digital assets can be collected, traded, or held for potential earnings.',
  },
  {
    title: 'Why should I purchase a Reality digital assets loot box instead of an individual digital assets?',
    details:
      'Buying a loot box gives you a higher chance of getting a rare and valuable digital assets at a lower cost than purchasing on the marketplace.',
  },
  {
    title: 'Can I sell or trade the digital assets I get from loot boxes?',
    details:
      'You can sell or trade the digital assets you gather from loot boxes as a regular Reality digital assets on compatible digital assets marketplaces.',
  },
  {
    title: 'How does the probability system work?',
    details:
      'The probability system determines how rare each digital assets is within a loot box. Each digital assets has a specific chance of being obtained based on its assigned probability percentage.',
  },
  {
    title: 'Are the items in Reality digital assets loot boxes randomly generated?',
    details:
      'Yes, the items in Reality digital assets loot boxes are randomly generated. The probability rates determine the chances of receiving different digital assets, making it unpredictable and exciting at the same time.',
  },
  {
    title: 'Are there different versions of loot boxes?',
    details:
      'Reality digital assets loot boxes come in two different categories: Silver and Gold. Each version contains digital assets related to its respective category.',
  },
  {
    title: 'What can I expect to find inside a Reality digital assets loot box?',
    details:
      'Inside a Reality digital assets loot box, you will discover a unique digital assets related to your selected category. For example, if you choose the Gold loot box, you might receive digital assets of higher tier like the Eiffel Tower or the Statue of Liberty.',
  },
  {
    title: 'Are the probability rates the same for all digital assets?',
    details: (
      <div>
        No, the rates vary for each digital assets. Some digital assets have higher chances of being obtained, while
        others are rare and harder to find, more details here:{' '}
        <a
          target="_blank"
          href="https://medium.com/@Realitymeta/the-new-winter-themed-loot-box-ab671efda44d"
          rel="noreferrer"
        >
          https://medium.com/@Realitymeta/the-new-winter-themed-loot-box-ab671efda44d
        </a>
      </div>
    ),
  },
  {
    title: 'Can I collect all the items in a specific category?',
    details:
      'Collecting all the items in a specific category is possible, but collecting the entire set may take multiple purchases.',
  },
  {
    title: 'Can I purchase multiple Reality digital assets loot boxes?',
    details:
      'You can purchase multiple Reality digital assets loot boxes as long as you have enough RMV tokens to cover the cost and transaction fees.',
  },
  {
    title: 'Is there a guarantee that I will receive a specific item from a loot box?',
    details: (
      <div>
        There is no guarantee of receiving a specific item from a loot box. Assets are randomly generated based on the
        probability rates assigned to each digital assets check full list here:
        <a
          target="_blank"
          href="https://medium.com/@Realitymeta/the-new-winter-themed-loot-box-ab671efda44d"
          rel="noreferrer"
        >
          https://medium.com/@Realitymeta/the-new-winter-themed-loot-box-ab671efda44d
        </a>
      </div>
    ),
  },
  {
    title: 'How can I purchase Reality digital assets loot boxes?',
    details: (
      <div>
        To purchase Reality digital assets loot boxes: Connect your crypto wallet to our website. Acquire RMV Polygon
        chain tokens from the available sources to cover the loot box price and transaction fees. Choose your loot box
        version (Landmarks, Cities, or Countries). Complete the transaction following the on-screen instructions.
        <br />
        <br />
        Your random fractional digital assets from the selected loot box will be added to your account. You can find it
        on your profile page.
      </div>
    ),
  },
  {
    title: 'How can I acquire RMV tokens?',
    details:
      'RMV tokens can be bought directly from our official website and various CEX/DEXs, please make sure to purchase RMV token on Polygon chain.',
  },
  {
    title: 'Where can I find more information about Reality digital assets loot boxes?',
    details: (
      <div>
        Additional information about Reality digital assets loot boxes can be found on our Medium page. Check the
        article dedicated to the loot boxes that provides further details, and guidelines:
        <a target="_blank" href="https://medium.com/@Realitymeta" rel="noreferrer">
          https://medium.com/@Realitymeta
        </a>
      </div>
    ),
  },
];

export const LANDING_FAQ_LIST: IFaqItem[] = [
  {
    title: 'What is Reality Metaverse?',
    details: [
      'Reality Metaverse is an innovative ecosystem that connects real-world locations with the digital world, creating new opportunities for ownership and monetization. By merging web3 technology with web2 gaming, Reality Metaverse allows player-investors to earn passive income through royalties from popular mobile games. The platform features a collection of over 2,000 digital assets, each representing famous landmarks, cities, and buildings from around the world. These assets are divided into fractional shares, allowing multiple owners to benefit from in-game revenues, marketplace transactions, and trading fees. The project aims to empower users by providing a seamless bridge between traditional gaming and blockchain technology, enhancing both engagement and profitability.',
    ],
  },
  {
    title: 'Who is behind Reality Metaverse?',
    details: [
      'Reality Metaverse is backed by Reality Games, a top-tier mobile gaming studio known for developing highly successful games across multiple platforms, engaging millions of players worldwide. The project is also supported by Golem, a leader in decentralized computing power, ensuring a robust and scalable technical infrastructure. Our diverse team includes experienced professionals in blockchain development, web3 innovation, digital assets, and metaverse strategy. With a shared vision of building a sustainable and rewarding ecosystem, the team is committed to delivering long-term value to players, investors, and partners alike.',
    ],
  },
  {
    title: 'How does Reality Metaverse differ from other metaverse projects?',
    details: [
      'Reality Metaverse stands apart by focusing on real-world value and sustainability. Unlike many projects that prioritize speculative hype, our ecosystem is designed to provide tangible benefits through established revenue streams in traditional mobile gaming. Our partnership with Reality Games gives us a unique advantage, leveraging their successful game portfolio to integrate real-world assets in ways that resonate with mainstream gamers. Moreover, our decentralized governance model ensures that RMV token holders play an active role in shaping the platform’s future. By emphasizing innovation, longevity, and continuous expansion, Reality Metaverse aims to build an ecosystem that not only rewards early adopters but also attracts new users over time, making it a truly scalable and profitable venture.',
    ],
  },
  {
    title: 'How can I buy RMV tokens?',
    details: [
      'Purchasing RMV tokens is easy and accessible across multiple platforms. The token is currently available on top centralized and decentralized exchanges, including KuCoin, Bitget, MexC, Poloniex, LBank, Bitmart, and Uniswap. Users can acquire RMV using various trading pairs such as USDT and ETH. Once purchased, RMV tokens can be used for in-game transactions, staking opportunities, governance participation, and unlocking exclusive premium content within the Reality Metaverse ecosystem. Stay updated on token listings and trading opportunities through our official channels.',
    ],
  },
];
